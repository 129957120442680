/*
        Google recaptcha object
    */

/**
 * Class to handle Google ReCaptcha
 *
 * @type {{validateUrl: string, defaultScriptUrl: string, defaultHtmlClass: string, init: Window.GoogleReCaptcha.init, isChecked: Window.GoogleReCaptcha.isChecked, getResponse: Window.GoogleReCaptcha.getResponse, reset: Window.GoogleReCaptcha.reset, validateResponse: Window.GoogleReCaptcha.validateResponse, getSiteKey: (function(): (string | undefined))}}
 */

window.GoogleReCaptcha = {

    // LS endpoint for APi that handles serverside validation.
    validateUrl: 'recaptcha/validate',

    // Script URL
    scriptUrl: 'https://www.google.com/recaptcha/api.js?onload=GoogleReCaptchaCallback&render=explicit',

    // Default HTML class of the ReCaptcha element
    htmlId: 'g-recaptcha',

    // Options
    defaultOptions: {},

    // Google ReCaptcha render params, siteKey will automatically be added.
    // For options please see docs: https://developers.google.com/recaptcha/docs/display#render_param
    // Options: theme, size, tabindex, callback, expired-callback, error-callback.
    renderParams: {},

    // Weather or not this is an invisible ReCaptcha
    isInvisible: false,

    // Defaults to the key on the default HTML tag
    siteKey: null,

    // Form ID
    formId: null,

    // Form response class
    formResponseClass: 'response',

    // Optional callback function so dev can handle error handling themselves
    googleErrorCallback: null,

    // Google error codes
    googleReCaptchaErrorCodes: {
        'missing-input-secret': 'The secret parameter is missing.',
        'invalid-input-secret': 'The secret parameter is invalid or malformed.',
        'missing-input-response	': 'The response parameter is missing.',
        'invalid-input-response	': 'The response parameter is invalid or malformed.',
        'bad-request': 'The request is invalid or malformed.',
        'timeout-or-duplicate': 'The response has has timed out or has already been used.',
    },

    // Custom errors
    errorMessages: {
        // The recaptcha has not been checked.
        'recaptchaNotCompleted': 'Please check the Google reCAPTCHA box.',
        // The box had been checked but the form was not submitted in time.
        'timeoutOrDuplicate': 'The Google reCAPTCHA box timed out, please try again.',
        // Error thats used if nothing else maches.
        'somethingWentWrong': 'The Google reCAPTCHA verification failed, please try again.'
    },

    /**
     * Handles any options passed into the init and also adds the Google ReCaptcha script to the body.
     * @param options
     */
    init: function(options) {

        if(options !== undefined) {
            console.log(options);
            // Override the default htmlClass used to render the ReCaptcha box.
            if('htmlId' in options) {
                this.htmlId = options.htmlId;
            }
            // Pass in a siteKey instead of having one on the htmlClass as a data attribute.
            if('siteKey' in options) {
                this.siteKey = options.siteKey;
            }
            // Allows params to be passed in from the google render function.
            if('renderParams' in options) {
                this.renderParams = options.renderParams;
            }
            // Override the validateUrl (URL for LS's server side validation controller)
            if('validateUrl' in options) {
                this.validateUrl = options.validateUrl;
            }
            // Override the default class for showing errors
            if('formResponseClass' in options) {
                this.formResponseClass = options.formResponseClass;
            }
            // Override the default script URL if needed for extra params or callback options.
            if('scriptUrl' in options) {
                this.scriptUrl = options.scriptUrl;
            }
            // Allows callback for failed ReCaptcha response, overrides built in error handling.
            if('googleApiCallback' in options) {
                this.googleApiCallback = options.googleApiCallback;
            }
            // Override error messages used by error handling (Error keys must stay the same).
            if ('errorMessages' in options) {
                this.errorMessages = options.errorMessages;
            }
        }

        // If the siteKey us still null, get it from the default htmlCLass elements data attribute.
        if(this.siteKey == null) {
            this.siteKey = this.getSiteKey();
        }

        if(this.renderParams.size !== undefined && this.renderParams.size == 'invisible') {
            this.isInvisible = true;
        }

        // Load the Google ReCaptcha script, this will automatically call the GoogleReCaptchaCallback method that
        // inturn calls GoogleReCaptcha.render()
        this.loadScript(this.scriptUrl);
    },

    /**
     * Renders the Recaptcha element.
     */
    render: function() {
        // Add the sitekey to the renderParams
        this.renderParams.siteKey = this.siteKey;
        // Render the Recaptcha element and pass in the params.
        grecaptcha.render(this.htmlId, this.renderParams);
    },

    /**
     * Checks if the user has checked the ReCaptcha box.
     * @returns {boolean}
     */
    isChecked: function() {
        if(grecaptcha && grecaptcha.getResponse().length > 0) {
            return true;
        }

        return false;
    },

    /**
     * After the checkbox has been checked you can get the response.
     * @returns string | null
     */
    getResponse: function() {
        if(grecaptcha && grecaptcha.getResponse().length > 0) {
            return grecaptcha.getResponse();
        }
        return null;
    },

    /**
     * Rests the ReCaptcha box state.
     */
    reset: function () {
        grecaptcha.reset();
    },

    /**
     * Executes the recap, used with invisible
     */
    execute: function() {
        grecaptcha.execute(this.htmlId);
    },

    /**
     * Tasks the ReCaptcha response and validates it.
     */
    validateResponse: function (callback) {
        if(this.isInvisible === true || this.isChecked() === true) {

            var that = this;
            LightSpeed.apiCall(this.validateUrl, {google_response: this.getResponse()}, 'POST',
                // LS apiCall success callback
                function(response) {
                    // Check to see if the google validation was successful.
                    if(response.data.googleResponse.success === true) {
                        // Check too make sure the callback passed in is a function.
                        if(typeof callback == "function") {
                            callback();
                        }
                    } else {
                        // If it gets here it means it failed on googles end.

                        // Calls the googleErrorCallback if one has been set.
                        if(that.googleErrorCallback !== null && typeof that.googleErrorCallback == "function") {
                            that.googleErrorCallback();
                            return false;
                        }

                        that.handleGoogleErrors(response);
                    }

                }
            );
            return;
        }

        // ReCaptcha has not been completed/checked.
        this.handleReCaptchaNotChecked();
    },

    handleGoogleErrors: function(response) {
        // As its errored we will reset the recaptcha box, and add the alert class to the form response element.
        this.reset();
        document.getElementsByClassName(this.formResponseClass)[0].classList.add("alert-danger");

        // This means the googleResponse has timed out and just needs to be re-completed
        if(response.data.googleResponse['error-codes'].indexOf('timeout-or-duplicate') !== -1) {
            // The response timed out and must be completed again.
            document.getElementsByClassName(this.formResponseClass)[0].innerHTML = this.errorMessages.timeoutOrDuplicate;
        } else {
            // If it isent timed out we will just get them to redo because all the other errors are server side and can not be handled client side.
            console.log(response);
            document.getElementsByClassName(this.formResponseClass)[0].innerHTML = this.errorMessages.somethingWentWrong;
        }
    },

    /**
     * Error handles when ReCaptcha has not been completed/checked.
     */
    handleReCaptchaNotChecked: function() {
        // Resets the ReCaptcha box
        this.reset();
        // Sets a class and error message on the response div.
        document.getElementsByClassName(this.formResponseClass)[0].classList.add("alert-danger");
        document.getElementsByClassName(this.formResponseClass)[0].innerHTML = this.errorMessages.recaptchaNotCompleted;
    },

    /**
     * Gets the site key form the HTML element
     * @returns {string | undefined}
     */
    getSiteKey: function () {
        var element = document.getElementById(this.htmlId);
        return element.dataset.sitekey;
    },

    /**
     * Dynamically loads the script if needed.
     * @param url
     */
    loadScript: function(url) {
        // Make a script DOM node
        var script = document.createElement("script");
        // Set it's src to the provided URL
        script.src = url;
        // Add it to the end of the head section of the page (could change 'head' to 'body' to add it to the end of the body section instead)
        document.body.appendChild(script);
    }

}

/**
 * Global method needed to for google so it can call the render inside the GoogleReCaptcha object.
 */
function GoogleReCaptchaCallback() {
    GoogleReCaptcha.render();
}



/* Get HTML5 tags working in IE 8. */
document.createElement('header');
document.createElement('nav');
document.createElement('article');
document.createElement('aside');
document.createElement('footer');
document.createElement('section');
jQuery(document).ready(function($) {
    // Open external links in new window. Also adds accessibility tags, and PDF Download option.
    var my_dom = window.location.hostname;
    if (my_dom.indexOf("www.") == 0) {
        my_dom = my_dom.substr(4);
    }
    $("a[href^='http://'],a[href^='https://']").each(function(){
        var this_dom = this.hostname;
        if (this_dom.indexOf("www.") == 0) {
            this_dom = this_dom.substr(4);
        }
        if (this_dom != my_dom) {
            $(this).attr("target", "_blank");
            if($(this).find(".sr-only").length==0){
                $(this).append(' <span class="sr-only">Opens in a new window</span>');
            }
            if(!$(this)[0].hasAttribute('rel')){
                $(this).attr('rel', 'noopener');
            }
        }
        if($(this).attr('href').indexOf('.pdf')!=-1){
            if($('.get-pdf-link').length==0){
                $(this).append('<a href="https://get.adobe.com/uk/reader/" class="get-pdf-link"><span class="sr-only">Download acrobat reader</span></a>');
            }
        }
    });

    var altCheck = setInterval(function() {
        var count = 0
        $("img").each(function () {
            if (typeof $(this).attr('alt') === 'undefined') {
                $(this).attr("alt", 'layout');
                count ++;
            }
        });

        if(count==0){
            clearInterval(altCheck);
        }

    }, 2000);


});
    //interim-manager-form

    // $(function(){
    //     var element = null;
    //     // Enables the GoogleReCaptcha, if there is a form on the page.
    //     if($("#contact-form").length) {
    //         GoogleReCaptcha.init({formResponseClass: 'message'});
    //         console.log('Form on this page.');
    //     }
    //
    //     $("#contact-form").submit(function(e){
    //         e.preventDefault();
    //         element = $(this);
    //         $(this).find(".response").removeClass("alert-danger alert-success").empty();
    //
    //         // If the GoogleReCaptcha successfully validates, callback and submit the form to LS.
    //         GoogleReCaptcha.validateResponse('submitForm');
    //
    //     });
    //
    //     function submitForm() {
    //         //var element = $("#contact-form");
    //         LightSpeed.submitContactForm(element, element.data("form-id"), 'POST',
    //             handleFormSuccess,
    //             handleFormError
    //         )
    //     }
    //
    //     function handleFormError(response){
    //
    //         var responseElem = $("#contact-form .message");
    //
    //         var message = "Oops, something went wrong.";
    //
    //         // If server error, die early.
    //         if(typeof response.data === 'undefined' || typeof response.data.errors === 'undefined' || typeof response.data.errors.length == 0){
    //             responseElem.html(message).addClass("alert-danger");
    //             return false;
    //         }
    //
    //         // if errors loop through and display:
    //         message += '<ul>';
    //
    //         response.data.errors.forEach(function(error){
    //
    //             // Create message. Check if nice message is in LightSpeed.errorCodes
    //             var errorMessage = '<li>';
    //
    //             if(typeof LightSpeed.errorCodes[error.code] !== 'undefined'){
    //                 errorMessage += LightSpeed.errorCodes[error.code];
    //             }else{
    //                 errorMessage += error.message;
    //             }
    //
    //             errorMessage += '</li>';
    //
    //             // If the error has a form field, identify it with has-error class.
    //             if(typeof error.field !== 'undefined' && error.field !== ''){
    //                 if(error.field == "name") {
    //                     $("#contact-form *[name='first_name']").addClass("has-error");
    //                     $("#contact-form *[name='last_name']").addClass("has-error");
    //                 } else {
    //                     $("#contact-form *[name='" + error.field + "']").addClass("has-error");
    //                 }
    //             }
    //
    //             // Append to the message output
    //             message += errorMessage;
    //
    //         });
    //
    //         message += '</ul>';
    //
    //         // Add message to response
    //
    //         responseElem.addClass("alert-danger").html(message);
    //
    //     }
    //
    //     function handleFormSuccess(response){
    //         $("#contact-form .message").addClass("alert-success").html("Thank you for registering your interest.");
    //     }
    //
    // });



$(function() {

    var element = null;

    if($("#contact-form").length) {
        GoogleReCaptcha.init();
    }

    $("#contact-formm").submit(function(e){
        e.preventDefault();

        console.log('Submit form');

        $(this).find(".response").removeClass("alert-danger alert-success").empty();

        element = $(this);

        // submitForm($(this));
        // submitFile($(this));
        GoogleReCaptcha.validateResponse(submitForm);
    });


    function submitForm() {
        LightSpeed.submitContactForm(element, element.data("form-id"), 'POST', handleFormSuccess, handleFormError);
    }

    function handleFormError(response){

        console.log(element);

        var responseElem = element.find('.response');

        var message = "Oops, something went wrong.";

        // If server error, die early.
        if(typeof response.data === 'undefined' || typeof response.data.errors === 'undefined' || typeof response.data.errors.length == 0){
            responseElem.html(message).addClass("alert-danger");
            return false;
        }

        // if errors loop through and display:
        message += '<ul>';

        response.data.errors.forEach(function(error){

            // Create message. Check if nice message is in LightSpeed.errorCodes
            var errorMessage = '<li>';

            if(typeof LightSpeed.errorCodes[error.code] !== 'undefined'){
                errorMessage += LightSpeed.errorCodes[error.code];
            }else{
                errorMessage += error.message;
            }

            errorMessage += '</li>';

            // If the error has a form field, identify it with has-error class.
            if(typeof error.field !== 'undefined' && error.field !== ''){
                element.find("*[name='" + error.field + "']").addClass("has-error");
            }

            // Append to the message output
            message += errorMessage;

        });

        message += '</ul>';

        // Add message to response

        responseElem.addClass("alert-danger").html(message);

    }

    function handleFormSuccess(response){
        console.log(element);
        element[0].reset();
        element.find(".has-error").removeClass("has-error");
        element.find(".file-name").empty();
        element.find('.cvStorageId').val('');
        element.find(".response").addClass("alert-success").html("Thank you for registering your interest.");
    }

});

