window.addEventListener("load", function(){
    window.cookieconsent.initialise({
        "palette": {
            "popup": {
                "background": "#bf9300"
            },
            "button": {
                "background": "#333333"
            }
        },
        "content": {
            "href": "https://wilgroup.net/privacy-and-cookie-policy"
        }
    })
});
