$(function() {

    var element = null;

    if($("#interim-manager-form").length) {
        GoogleReCaptcha.init();
    }

    $("#interim-manager-form").submit(function(e){
        e.preventDefault();

        console.log('Submit form');

        $(this).find(".response").removeClass("alert-danger alert-success").empty();

        element = $(this);

        // submitForm($(this));
        // submitFile($(this));
        GoogleReCaptcha.validateResponse(submitFile);
    });

    function submitForm() {
        LightSpeed.submitContactForm(element, element.data("form-id"), 'POST', handleFormSuccess, handleFormError);
    }

    function submitFile(callback) {
        //Gets the file input
        var fileElement = element.find("input[name=cover-letter]");

        // If there are no file in the input goes to the next
        if(!fileElement.prop('files').length > 0) {
            submitForm();
            return;
        }

        // Gets the file
        var file = fileElement.prop('files')[0];

        // Gets the file name
        var fileNiceName = file.name.split('.')[0];

        // Creates formData object and append all the needed data
        var formData = new FormData();
        formData.append('file', file);
        formData.append('nicename', fileNiceName);
        formData.append('is_public', true);
        formData.append('context', 'FORM_SUBMISSION');

        // Makes API call to upload file to storage
        return LightSpeed.apiCall('storage', formData, 'POST', function(response, element) {
            console.log("File Upload Success");
            console.log(response, element);

            // Save storage ID in local storage while the other calls complete
            localStorage.setItem('cv', response.data.storage.id);

            // If call back is true it will upload the next file if not will finish the form submission
            submitFormWithFile();
        },
        function (response) {
            handleFormError(response)
        }, true, element);
    }

    function submitFormWithFile() {

        /*var formData = {
            name: element.find("input[name=name]").val(),
            email: element.find("input[name=email]").val(),
            contact_number: element.find("input[name=contact_number]").val(),
            message: "N/A",
            fields: {}
        };*/

        if(localStorage.getItem('cv') !== null) {
            //formData.fields.cv_storage_id = parseInt(localStorage.getItem('cv'));
            $(".cvStorageId").val(parseInt(localStorage.getItem('cv')));
        }

        submitForm();

        // Submits the form with the file ids if there are any
        /*LightSpeed.apiCall('contact/'+element.data('form-id')+'/submission', formData, 'POST', function (response, element) {
            handleFormSuccess(response, element)
        }, function (response, element) {
            handleFormError(response, element)
        }, false, element);*/
    }

    function handleFormError(response){

        console.log(element);

        var responseElem = element.find('.response');

        var message = "Oops, something went wrong.";

        // If server error, die early.
        if(typeof response.data === 'undefined' || typeof response.data.errors === 'undefined' || typeof response.data.errors.length == 0){
            responseElem.html(message).addClass("alert-danger");
            return false;
        }

        // if errors loop through and display:
        message += '<ul>';

        response.data.errors.forEach(function(error){

            // Create message. Check if nice message is in LightSpeed.errorCodes
            var errorMessage = '<li>';

            if(typeof LightSpeed.errorCodes[error.code] !== 'undefined'){
                errorMessage += LightSpeed.errorCodes[error.code];
            }else{
                errorMessage += error.message;
            }

            errorMessage += '</li>';

            // If the error has a form field, identify it with has-error class.
            if(typeof error.field !== 'undefined' && error.field !== ''){
                element.find("*[name='" + error.field + "']").addClass("has-error");
            }

            // Append to the message output
            message += errorMessage;

        });

        message += '</ul>';

        // Add message to response

        responseElem.addClass("alert-danger").html(message);

    }

    function handleFormSuccess(response){
        console.log(element);
        element[0].reset();
        element.find(".has-error").removeClass("has-error");
        element.find(".file-name").empty();
        element.find('.cvStorageId').val('');
        element.find(".response").addClass("alert-success").html("Thank you for registering your interest.");
    }

});
