$(function () {

    $(".slider").bxSlider({
        mode: 'fade',
        auto: true,
        autoHover: false,
        pause: 5000,
        controls: false,
        touchEnabled: false,
        stopAutoOnClick: true
    });

    $('.members-slider').slick({
        infinite: false,
        slidesToShow: 4.5,
        slidesToScroll: 1,
        prevArrow: $('.prev-members'),
        nextArrow: $('.next-members'),
        responsive: [
            {
                breakpoint: 1124,
                settings: {
                    slidesToShow: 3.5,
                }
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 1.5,
                }
            },
        ]
    });

    $('.testimonials-slider').slick({
        infinite: false,
        slidesToShow: 2.5,
        slidesToScroll: 1,
        prevArrow: $('.prev-testimonials'),
        nextArrow: $('.next-testimonials'),
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 1124,
                settings: {
                    slidesToShow: 1.5,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });

    $(".testimonials-slider .read-more").on("click", function(e){
        e.preventDefault()
        $(this).parent().parent().parent().addClass("open")
    });


    $(".scroll-down").click(function () {
        var scrollTo = $(".home section").first().offset().top;
        $("html, body").animate({
            scrollTop: (scrollTo - 50)
        })
    })


});
