/*$(function(){

    $("#contact-form").submit(function(e){

        e.preventDefault();

        var form = $(this);

        var payload = {
            "name":form.find('input[name=name]').val(),
            "email":form.find('input[name=email]').val(),
            "message":form.find('textarea[name=message]').val(),
            "contact_number":form.find('input[name=number]').val(),
            "subject":form.find('input[name=number]').val(),
            "company":"",
            "fields":{
                "service":"",
            },
            "terms":[],
            "opt_ins":[]

        }


        $.post('https://wilgroup.net/api/contact/2/submission', payload, function(response){

            var message = "Something went wrong.";
            var messageClass = "";

            if(response.response.status=="success"){
                message = "Thank you for your enquiry. We will respond to you within 24 hours.";
                messageClass = "success";
            }else{
                messageClass = "error";
            }



            $("#contact-form").find('.message').removeClass("error success").addClass(messageClass+ " open");
            $("#contact-form").find('.message span').text(message);

        });


    });

});


{
    "name":"Matt",
    "email":"matt@speed.agency",
    "message":"This is a test contact form submission",
    "contact_number":"+441832280032",
    "subject":"Demonstrating The Form",
    "company":"Speed Agency",
    "fields":{

        "service":"Performance Improvement and Digital Transformation"
    },
    "terms":[],
    "opt_ins":[]
}
 */