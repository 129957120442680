$(function() {
    $(".enquiry-form form").submit(function (e) {
        $(".enquiry-form form .message span").html("");
        $(".enquiry-form form .message").removeClass("open");
        $(".enquiry-form form .message").removeClass("success");
        $(".enquiry-form form .message").removeClass("error");
        $(".enquiry-form form input[type='submit']").val('Sending');

        $(".enquiry-form form .form-submit").attr("disabled", "disabled");

        LightSpeed.submitContactForm($(this), $(this).data('form-id'), 'POST', formSubmitted, formFailed, false);

        e.preventDefault();
    });

    function formSubmitted(response){
        $(".enquiry-form form .message span").html("Thank you for your enquiry. We will respond to you within 24 hours.");
        $(".enquiry-form form .message").addClass("open");
        $(".enquiry-form form .message").addClass("success");
        $(".enquiry-form form input[type='submit']").val('Sent');

        $('.enquiry-form form').find("input[type=text], input[type=email], textarea").val("");
    }

    function formFailed(response){
        errors = '';
        message = '';

        errors = formreturn.response.data.errors;
        console.log(errors);

        message = "<ul>";

        $(errors).each(function (index) {
            console.log(index);
            message += "<li>" + this.message + "</li>";
        });

        message += "</ul>";

        $(".enquiry-form form .message span").html(message);
        $(".enquiry-form form .message").addClass("open");
        $(".enquiry-form form .message").addClass("error");
        $(".enquiry-form form .form-submit").removeAttr("disabled");
        $(".enquiry-form form input[type='submit']").val('Re-send');
    }
});


$("form#interim-manager-form, #contact-form").find('#where_are_you_based').change(function(){
   var sendTo = $(this).find(":selected").data('send-to');

    $("#send_to").val('');

   if(sendTo !== ''){
       $("#send_to").val(sendTo);
   }

});
